import { electricViolet, elfGreen, mineralGreen, bloodOrange } from './colors'
import { spacingMD, SpacingUnits } from './spacing'
import styled from 'styled-components'

export const fontSizeXXXS = `0.625rem` // 10px
export const fontSizeXXS = `0.75rem` // 12px
export const fontSizeXS = `0.875rem` // 14px
export const fontSizeSM = `1rem` // 16px
export const fontSizeMD = `1.25rem` // 20px
export const fontSizeLG = `1.5rem` // 24px
export const fontSizeHeader = `2.0rem` // 32px
export const fontSizeXL = `2.5rem` // 40px
export const fontSizeXXL = `3rem` // 48px
export type FontSizes =
  | typeof fontSizeXXS
  | typeof fontSizeXS
  | typeof fontSizeSM
  | typeof fontSizeMD
  | typeof fontSizeLG
  | typeof fontSizeXL
  | typeof fontSizeXXL

export const fontWeightThin = `300`
export const fontWeightNormal = `normal`
export const fontWeightSlightlyBold = `500`
export const fontWeightBold = `800`
export type FontWeights =
  | typeof fontWeightThin
  | typeof fontWeightNormal
  | typeof fontWeightBold
  | typeof fontWeightSlightlyBold

export const telegrafFont = "font-family: 'PPTelegraf', sans-serif;"

export const BaseText = `
  font-size: ${fontSizeSM};
  line-height: 1;
`

export const InputLabelText = `
  ${BaseText}
  font-weight: ${fontWeightSlightlyBold};
`

export const Subtext = `
  font-size: ${fontSizeXS};
  color: ${mineralGreen};
`

export const PurpleText = styled.span`
  color: ${electricViolet};
  font-weight: ${fontWeightSlightlyBold};
`

export const GreenText = styled.span`
  color: ${elfGreen};
`

export const BoldGreenText = styled(GreenText)`
  font-weight: ${fontWeightBold};
`

export const DarkGreenText = styled.span`
  color: ${mineralGreen};
`

export const BoldDarkGreenText = styled(DarkGreenText)`
  font-weight: ${fontWeightBold};
`

export const ThinDarkGreenText = styled.span`
  color: ${mineralGreen};
  line-height: ${fontSizeLG};
  font-weight: ${fontWeightThin};
`

export const OrangeText = styled.span`
  color: ${bloodOrange};
`

export const MediumBoldText = styled.span<{ enableTextWrap?: boolean }>`
  font-size: ${fontSizeMD};
  line-height: ${fontSizeLG};
  font-weight: ${fontWeightBold};
  ${({ enableTextWrap }) => (enableTextWrap ? `` : `white-space: pre`)};
`

export const CardLabel = styled.span`
  font-size: ${fontSizeMD};
  font-weight: ${fontWeightSlightlyBold};
`

export const TableLabel = styled.div<{
  marginBottom?: SpacingUnits
}>`
  font-size: ${fontSizeMD};
  line-height: ${fontSizeMD};
  font-weight: ${fontWeightBold};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : spacingMD)};
`
