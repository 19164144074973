import { EmployerCountry } from './employerInfo'

export enum CountryCode {
  US = 'US',
  Canada = 'CA',
  Germany = 'DE',
  Denmark = 'DK',
  Spain = 'ES',
  France = 'FR',
  UnitedKingdom = 'GB',
  Ireland = 'IE',
  Italy = 'IT',
  Netherlands = 'NL',
  Poland = 'PL',
  Portugal = 'PT',
  Sweden = 'SE',
  Austria = 'AT',
  SouthAfrica = 'ZA',
  NewZealand = 'NZ',
  Slovakia = 'SK',
  CzechRepublic = 'CZ',
  Mexico = 'MX',
  India = 'IN',
  Peru = 'PE',
  Chile = 'CL',
  Argentina = 'AR',
  Colombia = 'CO',
  ElSalvador = 'SV',
  Nigeria = 'NG',
  Japan = 'JP',
  Uruguay = 'UY',
  Brazil = 'BR',
  Ecuador = 'EC',
  Australia = 'AU',
  Slovenia = 'SI',
  UnitedArabEmirates = 'AE',
  Belgium = 'BE',
  Switzerland = 'CH',
  Kenya = 'KE',
  Thailand = 'TH',
  Philippines = 'PH',
  Greece = 'GR',
  Israel = 'IL',
  Georgia = 'GE',
  Norway = 'NO',
  Romania = 'RO'
}

export const CountryNames: Record<CountryCode, string> = {
  US: 'United States',
  CA: 'Canada',
  DE: 'Germany',
  DK: 'Denmark',
  ES: 'Spain',
  FR: 'France',
  GB: 'Great Britain',
  IE: 'Ireland',
  IT: 'Italy',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  SE: 'Sweden',
  AT: 'Austria',
  ZA: 'South Africa',
  NZ: 'New Zealand',
  SK: 'Slovakia',
  CZ: 'Czech Republic',
  MX: 'Mexico',
  IN: 'India',
  PE: 'Peru',
  CL: 'Chile',
  AR: 'Argentina',
  CO: 'Colombia',
  SV: 'El Salvador',
  NG: 'Nigeria',
  JP: 'Japan',
  UY: 'Uruguay',
  BR: 'Brazil',
  EC: 'Ecuador',
  AU: 'Australia',
  SI: 'Slovenia',
  AE: 'United Arab Emirates',
  BE: 'Belgium',
  CH: 'Switzerland',
  KE: 'Kenya',
  TH: 'Thailand',
  PH: 'Philippines',
  GR: 'Greece',
  IL: 'Israel',
  GE: 'Georgia',
  NO: 'Norway',
  RO: 'Romania'
}

export type EmployerCountryInfo = Omit<
  EmployerCountry,
  'employerId' | 'chargeFeePer' | 'processingFee' | 'autoPaymentMethodId'
>
